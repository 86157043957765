import { useMutation } from "@apollo/client";
import { createContext, PropsWithChildren, useState } from "react";
import { adminDataSource } from "../api/datasources";
import { postDataWithPayLoad } from "../api/fetchData";
import { CREATE_ELEMENT } from "../api/myMutations";
import ELEMENT_IMAGE from "../assets/images/no_image.jpg";
import ELEMENT_MOCK from "../data/mock.element_raw.json";
import checkForms from "../utils/checkForms";
import getToken from "../utils/getToken";

type ContextInfo = {
    elementInfo: RawElementInformation,
    // setElementInfo: (elementInfo: RawElementInformation) => {},
    handleChange: (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void,
    handleTypeChange: (evt: React.ChangeEvent<HTMLSelectElement>) => void,
    handleSubmit: (evt: React.FormEvent<HTMLFormElement>) => void,
    validInputs: string[],
    toast: boolean,
    setToast: React.Dispatch<React.SetStateAction<boolean>>
    toastProps: {
        title: string,
        body: string,
        footer: string,
        theme: string
    },
    setFile: React.Dispatch<React.SetStateAction<string | File>>
    setToastProps: React.Dispatch<React.SetStateAction<Toast>>
    selectedType: string
}

const ContextDefaultValue:ContextInfo = {
    elementInfo: ELEMENT_MOCK,
    // setElementInfo: (elementInfo: RawElementInformation) => {},
    handleChange: (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {},
    handleTypeChange: (evt: React.ChangeEvent<HTMLSelectElement>) => {},
    handleSubmit: (evt: React.FormEvent<HTMLFormElement>) => {},
    validInputs: [""],
    toast: false,
    setToast: () => false,
    toastProps: {
        title: "Titulo del toast",
        body: "Cuerpo del toast",
        footer: "Footer del toast",
        theme: "primary_theme"
    },
    setFile: () => {},
    setToastProps: () => {},
    selectedType: "Material"
}

export const CreateElementContext = createContext(ContextDefaultValue);

export default function CreateElementProvider({children}:PropsWithChildren){
    // Información de los elementos
    const [elementInfo, setElementInfo] = useState<RawElementInformation>(ELEMENT_MOCK);
    // Determina que formulario se va a mostrar dependiendo de la categoría del elemento seleccionado
    const [selectedType, setSelectedType] = useState<FormByType["selectedType"]>("Material");
    // Realiza chequeo de los inputs válidos
    const [validInputs, setValidInputs] = useState<string[]>([]);
    // Para subir imagenes
    const [file, setFile] = useState<string | File>(ELEMENT_IMAGE);

    const [createElement, { data: elementData, loading: elementLoading, error: elementError }] = useMutation(CREATE_ELEMENT, {
        variables: {
            elementData: elementInfo
        }
    })
    
    const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = evt.target.name;
        let value: string | number = evt.target.value;
        setElementInfo((prev) => {
            return ({
                ...prev,
                [name]: value
            })
        });
    }

    const handleTypeChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const name = evt.target.name;
        let value = evt.target.value as FormByType["selectedType"];
        setSelectedType(value)
        setElementInfo((prev) => {
            return ({
                ...prev,
                [name]: value
            })
        });
    }

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        const checks = new checkForms(elementInfo);

        elementInfo.history[0].takerFolder = elementInfo.takerFolder;
        elementInfo.history[0].giverFolder = elementInfo.takerFolder;

        //Upload Image
        const myImage = new FormData();
        myImage.append("image", file);

        try {
            const myResult = await postDataWithPayLoad(`${adminDataSource}/images/single`,myImage,getToken());
            const response = await myResult.json();
            if(myResult.status === 200) {
                elementInfo.image = response.res.path;
            }
            if(myResult.status === 400){
                elementInfo.image = "";
            }

        } catch (err) {
            setToastProps({
                title: "Error cargando Imagen...",
                body: "La imagen no pudo ser cargada",
                footer: `${err}`,
                theme: "error_theme"
            })
            setToast(true);
        }

        const checkedInputs = checks.checkEmpty({ name: "name", type: "string" }, { name: "serial", type: "string" }, { name: "description", type: "string" }, { name: "currentOwner", type: "string" }, { name: "takerFolder", type: "string" }, { name: "image", type: "string" }, { name: "category", type: "string" });
        setValidInputs(checkedInputs);

        if (checkedInputs.length === 0) {
            createElement();
            setToastProps({
                title: "Creación del elemento",
                body: "Elemento creado con éxito",
                footer: "Exito",
                theme: "primary_theme"
            })
            setToast(true);
        }
        else {
            setToastProps({
                title: "Error en la creación",
                body: "Verifica que todos los campos esten diligenciasdos",
                footer: "Error: Campos incompletos",
                theme: "error_theme"
            })
            setToast(true);
        }
    }

    const [toast, setToast] = useState(false);
    const [toastProps, setToastProps] = useState({
        title: "Titulo del toast",
        body: "Cuerpo del toast",
        footer: "Footer del toast",
        theme: "primary_theme"
    })

    return <CreateElementContext.Provider value={{elementInfo, handleChange, handleSubmit, validInputs, toast, toastProps, setFile, setToast, setToastProps, selectedType, handleTypeChange}}>
        {children}
    </CreateElementContext.Provider>
}