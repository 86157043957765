import { useEffect, useState } from "react";
import FOLDER_MOCK from "../data/mock.folder.json";
import { GET_FOLDERS } from "../api/myQueries";
import { useQuery } from "@apollo/client";

type SelectBox = {
    onChange: (
      evt: React.ChangeEvent<HTMLSelectElement>
    ) => void;
    label: string;
    name: string;
    defaultOption: {label: string, value: string}
    isEmpty: boolean
    value: string
  };
  export default function BodegaSelectBox({ onChange, label, name, defaultOption, isEmpty, value }: SelectBox) {
    useEffect(()=>{
      
    },[value])
    return (
        <div className={`input_container gap_12 ${isEmpty ? 'error' : ''}`}>
        <label htmlFor={name}>{label}</label>
        <select className="editable_input width_100" id={name} name={name} onChange={onChange} value={value}>
            <option value={defaultOption.value}>{defaultOption.label}</option>
            <BodegaOptions/>
        </select>
    </div>
    );
  }
  
  function BodegaOptions(){
    const {loading, error, data} = useQuery(GET_FOLDERS);
    
    if(data){
      return (data.folders.map((option:Bodega)=><option key={option._id} value={option._id}>{option.name}</option>));
    }

    if(loading){
      return (
        <option value="">Cargando Información</option>
    );
    }

    if(error){
      return (
        <option value="">Hubo un error</option>
    );
    }

    return <option value="">Estamos procesando la información</option>

  }