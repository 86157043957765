import { PropsWithChildren } from "react";
import ADD_ELEMENT from "../assets/icon/progman.exe_14_127-0.png";
import EDIT from "../assets/icon/xpsp2res.dll_14_900-8.png";
import SAVE from "../assets/icon/setupapi.dll_14_50-2.png";
import RQ_ERRORS from "../data/error.rq.json";

type RQMenu<T> = T & {
  setRqOption: React.Dispatch<React.SetStateAction<string>>;
  saveRq: () => void;
  rqInfo: RQControllTypes;
  setToast: React.Dispatch<React.SetStateAction<boolean>>;
  setToastProps: React.Dispatch<React.SetStateAction<Toast>>;
};
export default function RqMenu({
  setModal,
  setRqOption,
  saveRq,
  rqInfo,
  setToast,
  setToastProps,
}: RQMenu<Modal>) {
  const handleModal = (menu: "RQ_ITEMS" | "GENERAL") => {
    setModal(true);
    setRqOption(menu);
  };

  const handleSubmit = () => {
    if (rqInfo.rqItems.length === 0) {
      setToast(true);
      setToastProps({
        title: "Error en RQ",
        body: RQ_ERRORS[0].description,
        footer: `${RQ_ERRORS[0].title}: ${RQ_ERRORS[0].solution}`,
        theme: "error_theme",
      });
    }
    else{
        saveRq()
    }
  };
  return (
    <div className="bottom_start right" style={{ justifyContent: "flex-end" }}>
      <div className="options">
        <div
          className="option_container"
          onClick={() => handleModal("RQ_ITEMS")}
        >
          <img
            src={ADD_ELEMENT}
            alt="Añadir elemento"
            className="option_icon"
          />
        </div>
        <div
          className="option_container"
          onClick={() => {
            handleModal("GENERAL");
          }}
        >
          <img src={EDIT} alt="Editar" className="option_icon" />
        </div>
        <div className="option_container" onClick={handleSubmit}>
          <img src={SAVE} alt="Editar" className="option_icon" />
        </div>
      </div>
    </div>
  );
}
