
type SelectBox = {
    onChange: (
      evt: React.ChangeEvent< HTMLSelectElement>
    ) => void;
    label: string;
    name: string;
    isEmpty: boolean
    value: string
  };
  
  export default function CategorySelectBox({ onChange, label, name, isEmpty, value }: SelectBox) {
    return (
      <div className={`input_container gap_12 ${isEmpty ? 'error' : ''}`}>
        <label htmlFor={name}>{label}</label>
        <select className="editable_input width_100" id={name} name={name} onChange={onChange} value={value}>
            <option value="Material">Materiales</option>
            <option value="Herramientas">Herramientas</option>
            <option value="EPP">EPP</option>
            <option value="Dotación">Dotación</option>
            <option value="Equipos">Equipos</option>
        </select>
    </div>
    )
  }
