import Layout from "../components/layout";
import BottomStart from "../components/bottomStart";
import Title from "../components/title";
import Grid from "../components/grid";
import Card from "../components/cards";

import MENU_ITEMS from "../routing/bottomStart.json";
import { GET_PARENTFOLDERS } from "../api/myQueries";
import { useQuery } from "@apollo/client";
import ApolloErrorPage from "../components/apolloErrorPage";

export default function Folder() {
  const { loading, error, data } = useQuery(GET_PARENTFOLDERS);

  if (loading) return (
    <Layout>
    {/* Titulo de la página actual */}
    <Title
      title="Bodegas"
      description="Sitios de almacenaje de elementos"
    />
    <div className="pt_def_48"></div>
    {/* Barra de meníu inferior - shortcuts */}
    <Grid gap={12} sm={2} md={2} lg={2} def={1} className="">
    {/* @ts-ignore */}
    <div className="loader"></div>
    </Grid>
    <BottomStart />
  </Layout>
  );

  if (error){
    
    return (
      <Layout>
      {/* Titulo de la página actual */}
      <Title
        title="Bodegas"
        description="Sitios de almacenaje de elementos"
      />
      <div className="pt_def_48"></div>
      {/* Barra de meníu inferior - shortcuts */}
      <Grid gap={12} sm={2} md={2} lg={2} def={1} className="">
      {/* @ts-ignore */}
      <ApolloErrorPage error={error.graphQLErrors[0]} customCode="400"/>
      </Grid>
      <BottomStart />
    </Layout>
    );
  };

  if (data) {
    let myData = data.parentFolders as Bodega[];
    return (
      <Layout>
        {/* Titulo de la página actual */}
        <Title
          title="Bodegas"
          description="Sitios de almacenaje de elementos"
        />
        <div className="pt_def_48"></div>
        {/* Barra de meníu inferior - shortcuts */}
        <Grid gap={12} sm={2} md={2} lg={2} def={6} className="">
          {myData.map(({ name, image, _id }) => <Card name={name} icon={image} route={_id} key={_id} />
          )}
        </Grid>
        <BottomStart />
      </Layout>
    );
  }

  return <div>Holi!</div>
}
