export default class RQControll {
  rq: RQControllTypes;
  constructor(initialData: RQControllTypes) {
    this.rq = initialData;
  }

  get stateCopy() {
    return JSON.parse(JSON.stringify(this.rq)) as RQControllTypes;
  }

  set ppto(ppto: string) {
    this.rq.ppto = ppto;
  }

  static rqDate(dateNumber: number) {
    const date = new Date(dateNumber);
    const [month, day, year] = [
      date.getMonth(),
      date.getDate(),
      date.getFullYear(),
    ];
    return `${day}/${month + 1}/${year}`;
  }

  deleteRQItem(id: string) {
    const itemIndex = this.rq.rqItems.findIndex((item) => item["id"] === id);
    if (itemIndex > -1) {
      this.rq.rqItems.splice(itemIndex, 1);
    }
  }

  get rqToAPI(): RQControllAPI {
    this.parseAmounts();
    let rqJSONToAPI = {
      date: RQControll.rqDate(Date.now()),
      project: this.rq.project._id,
      rq: this.rq.rq,
      ppto: this.rq.ppto,
      rqItems: this.rq.rqItems,
      petitioner: this.rq.petitioner._id,
      isApproved: this.rq.isApproved
    };
    return rqJSONToAPI;
  }

  parseAmounts(){
    for(let i=0; i<this.rq.rqItems.length; i++){
      const currentItem = this.rq.rqItems[i];
      currentItem.authorizedAmount = Number(currentItem.authorizedAmount)
      currentItem.receivedAmount = Number(currentItem.receivedAmount)
      currentItem.requiredAmount = Number(currentItem.requiredAmount)
      currentItem.pendingAmount = Number(currentItem.pendingAmount)
    }
  }
}
