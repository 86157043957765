import { useState } from "react"
import { imagesSource } from "../api/datasources"
import { useMutation } from "@apollo/client";
import { DELETE_ELEMENT } from "../api/myMutations";
import { Link } from "react-router-dom";

interface ElementCardInfo {
    info: ElementInformation
}

export default function ElementCard(element:ElementCardInfo){
    console.log(element)
    const [isActive, setIsActive] = useState(false);
    const [deleteElementById, {loading, data, error}] = useMutation(DELETE_ELEMENT, {variables: {deleteElementById: element.info._id}})
    const handleDelete = () => {
        const deleteItem = confirm("Estas seguro que deseas eliminar este elemento?");
        if(deleteItem){
            deleteElementById();
        }
    }
    return (
        <div className="elementCard_container">
            <Link className="inside_container"  to={`/elemento/${element.info._id}`}>
            <div className="elementCard_image">
                <img src={`${imagesSource}${element.info.image}`} alt={element.info.name} />
            </div>
            <div className="elementCard_info">
                <p className="elementCard_title">{element.info.name}</p>
                <p>Serial: {element.info.serial}</p>
                <p>Ubicación: {element.info.takerFolder.name}</p>
                <p>Último movimiento: {}</p>
            </div>
            </Link>
            <div className="elementCard_options" onClick={()=>{setIsActive(!isActive)}}>
            <div className={`optionsDropdown ${isActive ? '':'hide'}`}>
                <ul>
                    <li onClick={handleDelete}>
                        <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z"/></svg><p>Eliminar</p>
                        </div>
                        </li>
                </ul>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/></svg>
            </div>
        </div>
    )
}