import BottomStart from "../components/bottomStart";
import Grid from "../components/grid";
import Layout from "../components/layout";
import Title from "../components/title";
import USER_IMAGE from "../assets/images/user.png";
import UserCard from "../components/userCard";
import USER_MENU from "../settings/userMenu.json";
import { useEffect, useState } from "react";
import ElementCard from "../components/elementCard";
import ELEMENTS from "../data/elements.json";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_ELEMENTS_BY_ID_AND_CATEGORY } from "../api/myQueries";
import { useParams } from "react-router-dom";

export default function UserPanel() {
  const [userMenu, setUserMenu] = useState(USER_MENU);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('Material');
  const {userId} = useParams();


  useEffect(() => {
    let USER_MENU_COPY = JSON.parse(JSON.stringify(USER_MENU));
    USER_MENU_COPY[activeIndex].isActive = true;
    setUserMenu(USER_MENU_COPY);
  }, []);

  const handleClick = (itemIndex: number) => {
    let USER_MENU_COPY = JSON.parse(JSON.stringify(USER_MENU));
    USER_MENU_COPY[itemIndex].isActive = true;
    setActiveIndex(itemIndex);
    setSelectedCategory(USER_MENU[itemIndex].name)
    setUserMenu(USER_MENU_COPY);
  };

  return (
    <Layout>
      {/* Titulo de la página actual */}
      <Title
        title="Gestión usuarios"
        description="A continuación selecciona lo que deseas hacer:"
      />
      <div className="pt_def_48"></div>
      {/* Barra de meníu inferior - shortcuts */}
      <Grid gap={12} def={6} sm={2} md={2} lg={2} className="">
        <div>
          <UserCard
            name="JAN MARTINEZ"
            cc={123456789}
            cargo="DIR. PROYECTOS"
            image={USER_IMAGE}
            id="1234"
          />
        </div>
        <div className="navigation_container col_s5">
          <div className="navigation_menu">
            {userMenu.map((item, index) => {
              return (
                <div
                  className={`navigation_tab ${item.isActive ? "active" : ""}`}
                  onClick={() => handleClick(index)} key={index}
                >
                  <span>{item.name}</span>
                  <div className="border_bottom"></div>
                </div>
              );
            })}
          </div>
          <div className="navigation_content" style={{ padding: 12 }}>
            <Grid sm={2} md={2} lg={2} gap={12} def={2} className="">
              <FilteredElements userId={userId} selectedCategory={selectedCategory}/>
            </Grid>
          </div>
        </div>
      </Grid>
      <BottomStart />
    </Layout>
  );
}

type FilteredElements = {
  userId: string | undefined,
  selectedCategory: string
}
function FilteredElements({userId, selectedCategory}: FilteredElements){
  const {data, error, loading} = useQuery(GET_ELEMENTS_BY_ID_AND_CATEGORY, {variables: {
    workerId: userId,
    categoryId: selectedCategory
  }})
  if(data){
    const elements:ElementInformation[] = data.getElementsByWorkerAndCategory 
    return (
      <>{elements.map(element => <ElementCard info={element}/>)}</>
    )
  }
  if(error){
    <p>Error</p>
  }
  if(loading){
    <div className="loading"></div>
  }
  return <p>Algo salió mal...</p>
}