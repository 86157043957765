import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../customHooks/centers/auth/useAuth";


export const ProtectedRoute = ({ children }: PropsWithChildren) => {
    /*@ts-ignore */
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
 return <>{children}</>;
};