import { ApolloError, QueryResult, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_OC_BY_ID } from "../api/myQueries";
import PRC_LOGO from "../assets/images/prica_full_color_logo.png";
import ApolloErrorPage from "../components/apolloErrorPage";
import BottomStart from "../components/bottomStart";
import Grid from "../components/grid";
import Layout from "../components/layout";
import Title from "../components/title";

export default function OCViewer() {

  return (
    <Layout>
      {/* Titulo de la página actual */}
      <Title title="Orden de compra" description="" />
      {/* OC TABLE */}
      <Grid def={1} gap={12} lg={3} md={3} sm={3}>
        <OCInfo/>
      </Grid>
      <BottomStart />
    </Layout>
  );
}

type OCResponseQuery = {
  getOcById: PricaOC
}

function OCInfo(){
  const {ocId} = useParams();
  const { loading, error, data }:QueryResult<OCResponseQuery> = useQuery(GET_OC_BY_ID, {
    variables: { ocId },
  });
  if(data){
    return <table className="ocTable">
            <thead className="logoHeadTable">
              <tr>
                <td colSpan={4}>
                  <img src={PRC_LOGO} alt="PRICA SAS" />
                </td>
                <td className="ocTitle">ORDEN DE COMPRA</td>
              </tr>
            </thead>
            <thead>
              <tr>
                <td className="primary_background borderGray">Razón social</td>
                <td colSpan={2} className="borderGray">
                  {data.getOcById.provider.name}
                </td>
                <td></td>
                <td className="primary_background borderGray">Fecha</td>
              </tr>
              <tr>
                <td className="primary_background borderGray">NIT</td>
                <td colSpan={2} className="borderGray">
                {data.getOcById.provider.nit}
                </td>
                <td></td>
                <td className="borderGray">{data.getOcById.date}</td>
              </tr>
              <tr>
                <td className="primary_background borderGray">Dirección</td>
                <td colSpan={2} className="borderGray">
                {data.getOcById.provider.address}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="primary_background borderGray">Ciudad</td>
                <td colSpan={2} className="borderGray">
                {data.getOcById.provider.city}
                </td>
                <td></td>
                <td className="primary_background borderGray">
                  Orden de compra No.
                </td>
              </tr>
              <tr>
                <td className="primary_background borderGray">Contacto</td>
                <td colSpan={2} className="borderGray">
                {data.getOcById.provider.contact}
                </td>
                <td></td>
                <td className="borderGray">35</td>
              </tr>
              <tr>
                <td className="primary_background borderGray">
                  Número de contacto
                </td>
                <td colSpan={2} className="borderGray">
                {data.getOcById.provider.contactNumber}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="primary_background borderGray">
                  Correo electrónico
                </td>
                <td colSpan={2} className="borderGray">
                {data.getOcById.provider.email}
                </td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tr>
              <td colSpan={5}>FACTURAR A</td>
            </tr>
            <thead>
              <tr>
                <td className="primary_background borderGray">Razón social</td>
                <td colSpan={4} className="borderGray">
                {data.getOcById.receiver.name}
                </td>
              </tr>
              <tr>
                <td className="primary_background borderGray">NIT</td>
                <td colSpan={4} className="borderGray">
                {data.getOcById.receiver.nit}
                </td>
              </tr>
              <tr>
                <td className="primary_background borderGray">Dirección</td>
                <td colSpan={4} className="borderGray">
                {data.getOcById.receiver.address}
                </td>
              </tr>
              <tr>
                <td className="primary_background borderGray">Ciudad</td>
                <td colSpan={4} className="borderGray">
                {data.getOcById.receiver.city}
                </td>
              </tr>
              <tr>
                <td className="primary_background borderGray">Contacto</td>
                <td colSpan={4} className="borderGray">
                {data.getOcById.receiver.contact}
                </td>
              </tr>
              <tr>
                <td className="primary_background borderGray">
                  Número de contacto
                </td>
                <td colSpan={4} className="borderGray">
                {data.getOcById.receiver.contactNumber}
                </td>
              </tr>
              <tr>
                <td className="primary_background borderGray">
                  Correo electrónico
                </td>
                <td colSpan={4} className="borderGray">
                {data.getOcById.receiver.email}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={5}> </td>
              </tr>
            </tbody>
            <thead className="generalInfo">
              <tr>
                <th className="primary_background">MÉTODO DE ENVÍO</th>
                <th className="primary_background">CONDICIONES DE ENVÍO</th>
                <th className="primary_background">ENVIAR A</th>
                <th className="primary_background">PAGO</th>
                <th className="primary_background">FECHA DE ENTREGA</th>
              </tr>
              <tr>
                <th>{data.getOcById.deliverMethod}</th>
                <th>{data.getOcById.deliverConditions}</th>
                <th>{data.getOcById.deliverAddress}</th>
                <th>{data.getOcById.paymentMethod}</th>
                <th>{data.getOcById.deliverDate}</th>
              </tr>
            </thead>
            <tr>
              <td colSpan={5}></td>
            </tr>
            <tbody className="ocBody">
              <tr>
                <td className="primary_background">ÍTEM</td>
                <td className="primary_background">DESCRIPCIÓN</td>
                <td className="primary_background">CANTIDAD</td>
                <td className="primary_background">PRECIO UNITARIO</td>
                <td className="primary_background">TOTAL</td>
              </tr>
              {data.getOcById.items.map((item, index)=>{
                return(
                  <tr>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.amount}</td>
                <td>PRECIO UNITARIO</td>
                <td>TOTAL</td>
              </tr>
                )
              })}
            </tbody>
            <tfoot>
              <tr>
                <td className="primary_background borderGray">Observaciones</td>
                <td colSpan={4} className="borderGray">
                {data.getOcById.observation}
                </td>
              </tr>
            </tfoot>
          </table>
  }
  if(loading){
    return(
      <div className="loader"></div>
    )
  }
  if(error){
    /* @ts-ignore */
    return <ApolloErrorPage customCode="500" error={error}/>
  }

  return <div className="loader"></div>
}
