export default class ElementControll {
  list: ElementInformation[];

  constructor(elementList: ElementInformation[]) {
    this.list = elementList;
  }

  ElementJSON(index: number): RawElementInformation {
    const raw = {
      name: this.list[index].name,
      description: this.list[index].description,
      code: this.list[index].code,
      serial: this.list[index].serial,
      image: this.list[index].image,
      history: this.historyToApi(this.list[index].history),
      currentOwner: this.list[index].currentOwner,
      giverFolder: this.list[index].giverFolder,
      takerFolder: this.list[index].takerFolder as unknown as string,
      onDelivery: this.list[index].onDelivery,
      category: this.list[index].category
    };
    return raw;
  }

  historyToApi(history: ElementInformation["history"]): RawHistory[] {
    return history.map((item) => {
      return {
        takerFolder: item.takerFolder._id,
        giverFolder: item.giverFolder._id,
        giver: item.giver._id,
        taker: item.taker._id,
      };
    });
  }

  search(key: "name" | "code" | "serial", query: string) {
    const searchExpression = new RegExp(`${query}`, "i");
    let results: ElementInformation[] = [];
    for (const elementInfo of this.list) {
      if (searchExpression.test(elementInfo[key])) {
        results.push(elementInfo);
      }
    }

    return results;
  }

  pagination({elementsPerPage,elementList,}: {elementsPerPage: number;elementList: ElementInformation[];}) {
    let pagination: ElementInformation[][] = [];
    for (let i = 0; i < elementList.length; i += elementsPerPage) {
      pagination.push(elementList.slice(i, i + elementsPerPage));
    }
    return pagination;
  }
}