import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { EDIT_ELEMENT, GET_ELEMENT_BY_ID } from "../api/myQueries";
import InputBox from "./inputElement";
import BodegaSelectBox from "./bodegaSelectBox";
import { useEffect, useState } from "react";
import ELEMENT_MOCK from "../data/mock.element.json";
import RAW_ELEMENT_MOCK from "../data/mock.element_raw.json";
import WorkerSelectBox from "./workerSelectBox";
import Grid from "./grid";
import { imagesSource } from "../api/datasources";
import checkForms from "../utils/checkForms";
import Toast from "./toast";
import ElementControll from "../utils/elements.controll";
import CategorySelectBox from "./categorySelectBox";
import ErrorPage from "./errorPage";
import ApolloErrorPage from "./apolloErrorPage";

export default function ElementById() {
    var { id } = useParams();
    const [elementInfo, setElementInfo] = useState(ELEMENT_MOCK);
    const [validInputs, setValidInputs] = useState<string[]>([]);
    const [rawElement, setRawElement] = useState(RAW_ELEMENT_MOCK);

    const { loading, error, data } = useQuery(GET_ELEMENT_BY_ID, {
        variables: { getElementById: id },
    });

    const [editElement, { data: elementData, loading: elementLoading, error: elementError }] = useMutation(EDIT_ELEMENT, {
        variables: {
            info: rawElement,
            editElementId: id
        }
    })

    const [toast, setToast] = useState(false);
    const [toastProps, setToastProps] = useState({
        title: "Titulo del toast",
        body: "Cuerpo del toast",
        footer: "Footer del toast",
        theme: "primary_theme"
    })

    const handleChange = (
        evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const name = evt.target.name;
        let value: string | number = evt.target.value;
        setElementInfo((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        console.log({elementInfo})
        const checks = new checkForms(elementInfo);
        const checkedInputs = checks.checkEmpty({ name: "name", type: "string" }, { name: "serial", type: "string" }, { name: "description", type: "string" }, { name: "currentOwner", type: "string" });
        setValidInputs(checkedInputs);
        if (checkedInputs.length === 0) {
            editElement();
            if(elementData){
               console.log(rawElement)
                console.log("ok")
            }
            if(elementError){
                console.log(elementError)
            }
            setToastProps({
                title: "Edición del elemento",
                body: "La información ha sido actualizada",
                footer: "Exito",
                theme: "primary_theme"
            })
            setToast(true);
        }
        else {
            setToastProps({
                title: "Error en la creación",
                body: "Verifica que todos los campos esten diligenciasdos",
                footer: "Error: Campos incompletos",
                theme: "error_theme"
            })
            setToast(true);
        }


    }

    useEffect(() => {
        if (data) {
            setElementInfo(data.getElementById)
        }
    }, [data])

    useEffect(() => {
      const RAW_ELEMENT = new ElementControll([elementInfo]);  
      setRawElement(RAW_ELEMENT.ElementJSON(0))
  }, [elementInfo])

    if (loading) {
        return <div className="loader"></div>;
    }

    if (data) {
        return (
          <>
          <Toast title={toastProps.title} body={toastProps.body} theme={toastProps.theme} footer={toastProps.footer} isActive={toast} setToast={setToast} />
          <form encType="multipart/form-data" onSubmit={handleSubmit}>
            <Grid gap={12} def={6} sm={2} md={2} lg={2} className="">
              {/* <InputImage  /> */}
              <div className="user_image_container">
                <div className="user_image">
                  <img
                    src={`${imagesSource}${elementInfo.image}`}
                    alt="Element image"
                  />
                </div>
              </div>
              <div className="new_user_info col_s5">
                <InputBox
                  onChange={handleChange}
                  inputName="name"
                  labelTag="Nombre"
                  isEmpty={validInputs.includes("name")}
                  value={elementInfo.name}
                />
                <InputBox
                  onChange={handleChange}
                  inputName="serial"
                  labelTag="Serial"
                  isEmpty={validInputs.includes("serial")}
                  value={elementInfo.serial}
                />
                <InputBox
                  onChange={handleChange}
                  inputName="description"
                  labelTag="Descripción"
                  isEmpty={validInputs.includes("description")}
                  value={elementInfo.description}
                />
                <WorkerSelectBox
                  defaultOption={{
                    label: "Selecciona un colaborador...",
                    value: "",
                  }}
                  name="currentOwner"
                  label="Persona a cargo"
                  onChange={handleChange}
                  isEmpty={validInputs.includes("currentOwner")}
                  value={elementInfo.currentOwner}
                />
                <BodegaSelectBox
                  defaultOption={{
                    label: "Selecciona una bodega...",
                    value: "",
                  }}
                  name="takerFolder"
                  label="Bodega"
                  onChange={handleChange}
                  isEmpty={validInputs.includes("takerFolder")}
                  value={elementInfo.takerFolder._id}
                />
                <CategorySelectBox isEmpty={validInputs.includes("category")} label="Categoría" name="category" onChange={handleChange} value={elementInfo.category}/>
                <div style={{ paddingTop: 24 }}>
                  <button className="mediumBottom" type="submit">
                    Actualizar
                  </button>
                </div>
              </div>
            </Grid>
            <div style={{ paddingTop: 16 }}></div>
            <Grid gap={12} def={1} sm={1} md={1} lg={1} className="">
              <h2>Historial del elemento</h2>
              <table>
                <thead>
                  <tr>
                    <th>Último a cargo</th>
                    <th>Actual a cargo</th>
                    <th>Bodega anterior</th>
                    <th>Bodega Actual</th>
                  </tr>
                </thead>
                <tbody>
                  {elementInfo.history.map((history: any ,index: number) => {
                      return (
                        <tr key={index}>
                          <td>{history.giver.name}</td>
                          <td>{history.taker.name}</td>
                          <td>{history.giverFolder.name}</td>
                          <td>{history.takerFolder.name}</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Grid>
          </form>
          </>
        );
    }

    if (error) {
      //@ts-ignore
      return (<ApolloErrorPage error={error.graphQLErrors[0]} customCode="400"/>
        );
    }

    return <div>No se ha podido cargar la información :(</div>;
}
