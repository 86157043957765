import { gql } from "@apollo/client";

export const CREATE_USER = gql`mutation AddUser($userData: UserInput!) {
  addUser(userData: $userData) {
    code
    success
    message
    user {
      name
      cc
      role
      image
      password
      _id
    }
  }
}`

export const CREATE_WORKER = gql`mutation AddWorker($workerInfo: WorkerInput!) {
    addWorker(workerInfo: $workerInfo) {
      code
      success
      message
      worker {
        name
        cc
        occupation
        image
        _id
      }
    }
  }`

  export const CREATE_ELEMENT = gql`mutation CreateElement($elementData: ElementInput!) {
    createElement(elementData: $elementData) {
      code
      success
      message
      element {
        name
        description
        code
        serial
        image
        history {
          giverFolder{
          name
          _id
        }
          takerFolder{
          name
          _id
        }
          giver{
          name
          _id
        }
          taker{
          name
          _id
        }
        }
        currentOwner
        giverFolder
        takerFolder
        onDelivery
        category
        _id
      }
    }
  }`

  export const DELETE_ELEMENT = gql`
  mutation DeleteElementById($deleteElementById: String!) {
  deleteElementById(id: $deleteElementById) {
    name
    description
    code
    serial
    image
    history {
      giverFolder{
          name
          _id
        }
      takerFolder{
          name
          _id
        }
      giver{
          name
          _id
        }
      taker{
          name
          _id
        }
    }
    currentOwner
    giverFolder
    takerFolder
    onDelivery
    _id
  }
}
  `

  export const CREATE_RQ = gql`mutation AddRQ($rqData: RQInputData!) {
    addRQ(rqData: $rqData) {
      code
      success
      message
      rq {
        date
        project {
          name
          _id
        }
        rq
        ppto
        rqItems {
          type
          description
          unit
          requiredAmount
          authorizedAmount
          receivedAmount
          pendingAmount
          observation
          code
          id
        }
        petitioner {
          name
          _id
        }
        isApproved
        _id
      }
    }
  }
  `

  export const APPROVE_RQ = gql`mutation ApproveRq($approveState: String!, $rqId: String!) {
    approveRq(approveState: $approveState, rqId: $rqId) {
      code
      success
      message
      rq {
        date
        project {
          name
          _id
        }
        rq
        ppto
        rqItems {
          type
          description
          unit
          requiredAmount
          authorizedAmount
          receivedAmount
          pendingAmount
          observation
          code
          id
        }
        petitioner {
          name
          _id
        }
        isApproved
        _id
      }
    }
  }`