import { Link } from "react-router-dom";
import LOGO_BLUE from "../assets/images/prica_logo_blue.png";
import LOGO_COLOR from "../assets/images/prica_logo_color.png";
import USER_LOGO from "../assets/images/user.png"

export default function UserCard({name, cc, cargo, id}:User){
    return(
        <Link className="userCard_container " to={`${id}`}>

            <div className="userCard_header">
                <div className="userCard_logo">
                    <img src={LOGO_COLOR} alt="logo" />
                </div>
                <div className="userCard_pricaName">
                    <div className="userCard_shortname">
                        <p>PRICA SAS</p>
                    </div>
                    <div className="userCard_longName">
                        <p>PROYECTOS DE INGENIERÍA, CONSULTORÍA Y ASESORÍA</p>
                    </div>
                </div>
            </div>
            <div className="userCard_user">
                <div className="userCard_userImage">
                    <img src={USER_LOGO} alt="user" />
                </div>
                <div className="userCard_userName">
                    <p>{name}</p>
                </div>  
                <div className="userCard_userCc">
                    <p>C.C: {cc}</p>
                </div>
            </div>
            <div className="userCard_cargo">
                <p>{cargo}</p>
            </div>
            <div className="userCard_background">
                <img src={LOGO_BLUE} alt="logo" draggable={false}/>
            </div>
        </Link>
    );
}