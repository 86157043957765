import { Link } from "react-router-dom"
import RQControll from "../utils/rq.controll"

type RQCard = {
    cardInfo: RQControllTypes
}
export default function RqCard({cardInfo}:RQCard){
    return (
        <Link className="rqCardContainer" to={`/requisicion/viewer/${cardInfo._id}`}>
            <p className="rqTitle">{cardInfo.rq}</p>
            <p className="rqDate">Fecha de creación: {RQControll.rqDate(cardInfo.date)}</p>
            <p className="rqDate">Proyecto: {cardInfo.project.name}</p>
            <p className="rqPetitioner">Solicitante: {cardInfo.petitioner.name}</p>
        </Link>
    )
}