import Layout from "../components/layout";
import BottomStart from "../components/bottomStart";
import Title from "../components/title";
import Grid from "../components/grid";

import USERS from "../data/users.json";

import UserCard from "../components/userCard";
import BigButton from "../components/bigButton";

export default function GestionUsuarios() {
  // const userInfo = JSON.parse(localStorage.getItem("estinorteLoggedUser") || "{}")
  // const role = userInfo.role
  return (
      <Layout>
        {/* Titulo de la página actual */}
        <Title title="Gestión usuarios" description="A continuación selecciona lo que deseas hacer:"/>
        <div className="pt_def_48"></div>
        {/* Barra de meníu inferior - shortcuts */}
        <Grid gap={12} def={6} sm={2} md={2} lg={2} className="">
          {USERS.map((item, index)=><UserCard name={item.name} cc={item.cc} cargo={item.cargo} image={item.image} id="66c4eb87f8592f3a68a7cf5d" key={index}/>)}
        </Grid>
        <Grid gap={12} def={3} sm={2} md={2} lg={2} className="">
          <BigButton text="+ Añadir nuevo usuario" link="/create/user"/>
        </Grid>
        <BottomStart/>
      </Layout>
  );
}
