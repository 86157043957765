import Layout from "../components/layout";
import BottomStart from "../components/bottomStart";
import Title from "../components/title";
import Grid from "../components/grid";
import Card from "../components/cards";

import MENU_ITEMS from "../routing/bottomStart.json";
import { GET_CHILDRENFOLDERS } from "../api/myQueries";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

export default function Subfolder() {

  var {parentId} = useParams();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_CHILDRENFOLDERS, {variables: {parentId: parentId} });

  if (loading) return <div className="loader"></div>;

  if (error) return <p>Error : {error.message}</p>;

  if (data) {
    let myData = data.folderByParentId as Bodega[];
    if(myData.length === 0){
      navigate(`/bodega/info/${parentId}`, {replace: true});
    }
    return (
      <Layout>
        {/* Titulo de la página actual */}
        <Title
          title="Bodegas"
          description="Sitios de almacenaje de elementos"
        />
        <div className="pt_def_48"></div>
        {/* Barra de meníu inferior - shortcuts */}
        <Grid gap={12} sm={2} md={2} lg={2} def={6}>
          {myData.map(({ name, image, _id }) => <Card name={name} icon={image} route={`/bodega/info/${_id}`} key={_id} />
          )}
        </Grid>
        <BottomStart />
      </Layout>
    );
  }

  return <div>Oops, ha ocurrido un error;</div>
}
