import NEW_MATERIAL_MOCK from "../data/mock.material.json";
import { useMutation } from "@apollo/client";
import { ADD_MATERIAL } from "../api/myMutations";
import { useEffect, useState } from "react";
import Modal from "./modal";
import InputBox from "./inputElement";
import CategorySelectBox from "./categorySelectBox";
import MaterialIcon from "../assets/icon/wmploc.dll_14_1110-6.png"
import Toast from "./toast";

export default function NewMaterialCard() {
  const [modal, setModal] = useState(false);
  const [newMaterial, setNewMaterial] = useState(NEW_MATERIAL_MOCK);
  const [storeMaterials, { loading, data, error }] = useMutation(ADD_MATERIAL, {
    variables: { materialData: newMaterial },
  });
  //Creacion de materiales
  const handleNewMaterial = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const value = evt.target.value;
    const name = evt.target.name;
    setNewMaterial((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const saveNewMaterial = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    storeMaterials();
  };

    //Toast Inicialization
    const [toast, setToast] = useState(false);
    const [toastProps, setToastProps] = useState({
      title: "Titulo del toast",
      body: "Cuerpo del toast",
      footer: "Footer del toast",
      theme: "primary_theme",
    });

    useEffect(() => {
        if(data){
            setToast(true)
            setToastProps({
                title: "Creación de material",
                body: "Material creado exitosamente",
                footer: "SUCCESS",
                theme: "primary_theme",
              })
        }
        if(error){
            setToast(true)
            setToastProps({
                title: "Creación de material",
                body: "Error creando el material",
                footer: "ERROR",
                theme: "error_theme",
              })
        }
    }, [loading, data, error]);

  return (
    <>
    <Toast
        title={toastProps.title}
        body={toastProps.body}
        theme={toastProps.theme}
        footer={toastProps.footer}
        isActive={toast}
        setToast={setToast}
      />
      <Modal modal={modal} setModal={setModal}>
        <form onSubmit={saveNewMaterial}>
          <InputBox
            inputName="name"
            isEmpty={false}
            labelTag="Nombre del material"
            onChange={handleNewMaterial}
            value={newMaterial.name}
            type="text"
          />
          <div className="pt_def_12"></div>
          <CategorySelectBox isEmpty={false} label="Seleccione el tipo" name="type" onChange={handleNewMaterial} value={newMaterial.type} />
          <div className="pt_def_12"></div>
          <InputBox inputName="unit" isEmpty={false} labelTag="Unidad de medida" onChange={handleNewMaterial} value={newMaterial.unit} type="text" />
          <div className="pt_def_12"></div>
          <button className="mediumBottom" type="submit">
            Agregar material
          </button>
        </form>
      </Modal>
      <div className="card_container select_none" style={{ cursor: "pointer" }} onClick={() => setModal(true)}>
        <div className="card_icon">
          <img src={MaterialIcon} alt={"Materiales"} draggable={false} />
        </div>
        <div className="card_name">
          <p className="select_none">Materiales</p>
        </div>
      </div>
    </>
  );
}
