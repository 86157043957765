import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ElementFromQuery } from "../@types/elementTypes";
import { imagesSource } from "../api/datasources";
import { EDIT_ELEMENT, GET_ELEMENT_BY_ID } from "../api/myQueries";
import FROM_QUERY_ELEMENT from "../data/mock.element.json";
import checkForms from "../utils/checkForms";
import ElementEditor from "../utils/elementEditor.controll";
import ApolloErrorPage from "./apolloErrorPage";
import BodegaSelectBox from "./bodegaSelectBox";
import CategorySelectBox from "./categorySelectBox";
import Grid from "./grid";
import InputBox from "./inputElement";
import Modal from "./modal";
import Toast from "./toast";
import WorkerSelectBox from "./workerSelectBox";

export default function ElementById({ elementEditor }: { elementEditor: ElementEditor }) {
  var { id } = useParams();

  const [elementInfo, setElementInfo] = useState<ElementFromQuery>(FROM_QUERY_ELEMENT as ElementFromQuery);
  const [validInputs, setValidInputs] = useState<string[]>([]);
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("FOLDER");

  const { loading, error, data } = useQuery(GET_ELEMENT_BY_ID, {
    variables: { getElementById: id },
  });

  const [editElement, { data: elementData, loading: elementLoading, error: elementError }] = useMutation(EDIT_ELEMENT, {
    variables: {
      info: elementEditor.toApi,
      editElementId: id,
    },
  });

  const [toast, setToast] = useState(false);
  const [toastProps, setToastProps] = useState({
    title: "Titulo del toast",
    body: "Cuerpo del toast",
    footer: "Footer del toast",
    theme: "primary_theme",
  });

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const name = evt.target.name;
    let value: string | number = evt.target.value;
    //@ts-ignore
    elementEditor.element[name] = value;
    setElementInfo(elementEditor.stateCopy);
  };

  const handleChangeFolderWithHistory = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const name = evt.target.options[evt.target.selectedIndex].text;
    let value: string | number = evt.target.value;
    //Defining new taker and giver folder
    // New giver folder is the current takerFolder if onDelivery flag is set to true
    const newGiverFolder = {
      _id: elementEditor.element.onDelivery ? elementEditor.element.giverFolder._id : elementEditor.element.takerFolder._id,
      name: elementEditor.element.onDelivery ? elementEditor.element.giverFolder.name : elementEditor.element.takerFolder.name,
    };
    //newTakerFolder is the value selected by the user
    const newTakerFolder = {
      name,
      _id: value,
    };

    const acceptChange = confirm("¿Estás seguro que deseas mover el elemento de bodega? - Esta acción no se puede deshacer");
    if (acceptChange) {
      //Update takerFolder and giverFolder with the new info
      elementEditor.element.takerFolder = newTakerFolder;
      elementEditor.element.giverFolder = newGiverFolder;

      const historyItem = JSON.parse(
        JSON.stringify({
          giver: {
            _id: elementEditor.element.currentOwner,
            name: "",
          },
          giverFolder: newGiverFolder,
          taker: {
            _id: elementEditor.element.currentOwner,
            name: "",
          },
          takerFolder: newTakerFolder,
        })
      );
      elementEditor.element.history.push(historyItem);
      setElementInfo(elementEditor.stateCopy);
    }
  };

  const handleChangeWorkerWithHistory = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const name = evt.target.options[evt.target.selectedIndex].text;
    let value: string | number = evt.target.value;
    //newTakerFolder is the value selected by the user
    const newOwner = {
      name,
      _id: value,
    };

    const acceptChange = confirm("¿Estás seguro que deseas mover el elemento de bodega? - Esta acción no se puede deshacer");
    if (acceptChange) {
      //Update takerFolder and giverFolder with the new info
      

      const historyItem = JSON.parse(
        JSON.stringify({
          giver: {
            _id: elementEditor.element.currentOwner,
            name: "",
          },
          giverFolder: elementEditor.element.giverFolder,
          taker: newOwner,
          takerFolder: elementEditor.element.takerFolder,
        })
      );
      elementEditor.element.currentOwner = value;
      elementEditor.element.history.push(historyItem);
      setElementInfo(elementEditor.stateCopy);
    }
  };

  const updateHistory = () => {
    editElement({
      variables: {
        info: elementEditor.toApi,
        editElementId: id,
      },
    });
  };

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const checks = new checkForms(elementInfo);
    const checkedInputs = checks.checkEmpty(
      { name: "name", type: "string" },
      { name: "serial", type: "string" },
      { name: "description", type: "string" },
      { name: "currentOwner", type: "string" }
    );
    setValidInputs(checkedInputs);
    if (checkedInputs.length === 0) {
      editElement({
        variables: {
          info: elementEditor.toApi,
          editElementId: id,
        },
      });
      if (elementData) {
        console.log("ok");
      }
      if (elementError) {
        console.log(elementError);
      }
      setToastProps({
        title: "Edición del elemento",
        body: "La información ha sido actualizada",
        footer: "Exito",
        theme: "primary_theme",
      });
      setToast(true);
    } else {
      setToastProps({
        title: "Error en la creación",
        body: "Verifica que todos los campos esten diligenciasdos",
        footer: "Error: Campos incompletos",
        theme: "error_theme",
      });
      setToast(true);
    }
  };

  useEffect(() => {
    if (data) {
      elementEditor.element = JSON.parse(JSON.stringify(data.getElementById)) as ElementFromQuery;
      setElementInfo(data.getElementById);
    }
  }, [data]);

  useEffect(()=>{
    if(elementLoading){
      setToastProps({
          title: "Actualización en curso",
          body: "Guardando información, espere...",
          footer: "SUCCESS",
          theme: "primary_theme"
      })
      setToast(true);
  }
  if(elementData){
      setToastProps({
          title: "Actualización del elemento",
          body: "El elemento se ha actualizado exitosamente",
          footer: "SUCCESS",
          theme: "primary_theme"
      })
      setToast(true);
  }
  if(elementError){
      setToastProps({
          title: "Actualización del elemento",
          body: "Ha ocurrido un error actualizando la información del elemento",
          footer: "ERROR",
          theme: "error_theme"
      })
      setToast(true);
  }
  },[elementLoading, elementError, elementData])

  const modalChild = () => {
    if (activeTab === "WORKER") {
      return (
        <Grid gap={12} def={1} sm={2} md={2} lg={2}>
          <WorkerSelectBox
            defaultOption={{
              label: "Selecciona un colaborador...",
              value: "",
            }}
            name="currentOwner"
            label="Persona a cargo"
            onChange={handleChangeWorkerWithHistory}
            isEmpty={validInputs.includes("currentOwner")}
            value={elementInfo.currentOwner}
            disabled={false}
          />
          <button className="btn mediumBottom" onClick={() => updateHistory()}>
            Registrar cambio
          </button>
        </Grid>
      );
    }

    if (activeTab === "FOLDER") {
      return (
        <Grid gap={12} def={1} sm={2} md={2} lg={2}>
          <BodegaSelectBox
            defaultOption={{
              label: "Selecciona una bodega...",
              value: "",
            }}
            name="takerFolder"
            label="Bodega"
            onChange={handleChangeFolderWithHistory}
            isEmpty={false}
            value={elementInfo.takerFolder._id}
            disabled={false}
            className="defaultButton"
          />
          <button className="btn mediumBottom" onClick={() => updateHistory()}>
            Registrar cambio
          </button>
        </Grid>
      );
    }
  };

  if (loading) {
    return <div className="loader"></div>;
  }

  if (data) {
    return (
      <>
        <Toast
          title={toastProps.title}
          body={toastProps.body}
          theme={toastProps.theme}
          footer={toastProps.footer}
          isActive={toast}
          setToast={setToast}
        />
        <Modal modal={modal} setModal={setModal}>
          {modalChild()}
        </Modal>
        <form encType="multipart/form-data" onSubmit={handleSubmit}>
          <Grid gap={12} def={6} sm={2} md={2} lg={2} className="">
            {/* <InputImage  /> */}
            <div className="user_image_container">
              <div className="user_image">
                <img src={`${imagesSource()}/${elementInfo.image}`} alt="Element image" />
              </div>
            </div>
            <div className="new_user_info col_s5">
              <InputBox
                onChange={handleChange}
                inputName="name"
                labelTag="Nombre"
                isEmpty={validInputs.includes("name")}
                value={elementInfo.name}
                type="text"
              />
              <InputBox
                onChange={handleChange}
                inputName="serial"
                labelTag="Serial"
                isEmpty={validInputs.includes("serial")}
                value={elementInfo.serial}
                type="text"
              />
              <InputBox
                onChange={handleChange}
                inputName="description"
                labelTag="Descripción"
                isEmpty={validInputs.includes("description")}
                value={elementInfo.description}
                type="text"
              />
              <InputBox
                onChange={handleChange}
                inputName="provider"
                labelTag="Proveedor"
                isEmpty={validInputs.includes("provider")}
                value={elementInfo.provider}
                type="text"
              />
              <InputBox
                onChange={handleChange}
                inputName="amount"
                labelTag="Cantidad"
                isEmpty={validInputs.includes("amount")}
                value={`${elementInfo.amount}`}
                type="number"
              />
              <WorkerSelectBox
                defaultOption={{
                  label: "Selecciona un colaborador...",
                  value: "",
                }}
                name="currentOwner"
                label="Persona a cargo"
                onChange={handleChange}
                isEmpty={validInputs.includes("currentOwner")}
                value={elementInfo.currentOwner}
                disabled={true}
              />
              <BodegaSelectBox
                defaultOption={{
                  label: "Selecciona una bodega...",
                  value: "",
                }}
                name="takerFolder"
                label="Bodega"
                onChange={handleChange}
                isEmpty={validInputs.includes("takerFolder")}
                value={elementInfo.takerFolder._id}
                disabled={true}
              />
              <CategorySelectBox
                isEmpty={validInputs.includes("category")}
                label="Categoría"
                name="category"
                onChange={handleChange}
                value={elementInfo.category}
                disabled={false}
              />
              <Grid gap={12} def={3} sm={1} md={1} lg={1}>
                <button className="mediumBottom" type="submit">
                  Actualizar
                </button>
                <a
                  className="mediumBottom defaultButton"
                  onClick={() => {
                    setModal(true);
                    setActiveTab("FOLDER");
                  }}
                >
                  Mover
                </a>
                <a
                  className="mediumBottom defaultButton"
                  onClick={() => {
                    setModal(true);
                    setActiveTab("WORKER");
                  }}
                >
                  Encargado
                </a>
              </Grid>
            </div>
          </Grid>
          <div style={{ paddingTop: 16 }}></div>
          <Grid gap={12} def={1} sm={1} md={1} lg={1}>
            <h2>Historial del elemento</h2>
            <table>
              <thead>
                <tr>
                  <th>Último a cargo</th>
                  <th>Actual a cargo</th>
                  <th>Bodega anterior</th>
                  <th>Bodega Actual</th>
                </tr>
              </thead>
              <tbody>
                {elementInfo.history.map((history, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{history.giver.name}</td>
                      <td>{history.taker.name}</td>
                      <td>{history.giverFolder.name}</td>
                      <td>{history.takerFolder.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Grid>
        </form>
      </>
    );
  }

  if (error) {
    //@ts-ignore
    return <ApolloErrorPage error={error.graphQLErrors[0]} customCode="400" />;
  }

  return <div>No se ha podido cargar la información :(</div>;
}
