import { Link, Route, Routes } from "react-router-dom";
import Start from "./routes/start";
import Folder from "./routes/folder";
import GestionUsuarios from "./routes/gestionUsuarios";
import CreateUser from "./routes/createUser";
import Elementos from "./routes/elements";
import UserPanel from "./routes/userPanel";
import CreateElement from "./routes/createElement";
import Subfolder from "./routes/subfolder";
import Login from "./routes/login";
import { AuthProvider } from "./customHooks/centers/auth/useAuth";
import { ProtectedRoute } from "./routes/protectedRoute";
import { loremEaster } from "./utils/lorem";
import ElementInformation from "./routes/element";
import BodegaInfo from "./routes/bodegaInfo";
import RQEditor from "./routes/rqEditor";
import RQStart from "./routes/rqStart";
import RQViewer from "./routes/rqViewer";
import RQViewList from "./routes/rqView";
import OCViewer from "./routes/ordenViewer";
import OCEditor from "./routes/ordenEditor";

//PAGES

function App() {
  // console.log(`%c${loremEaster}`, "color: beige");

  return (
    <div className="App">
      <AuthProvider>
      <Routes>
      <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute><Start /></ProtectedRoute>} />
        <Route path="/bodega" element={<ProtectedRoute><Folder/></ProtectedRoute>} />
        <Route path="/requisicion" element={<ProtectedRoute><RQStart/></ProtectedRoute>} />
        <Route path="/requisicion/editor" element={<ProtectedRoute><RQEditor/></ProtectedRoute>} />
        <Route path="/requisicion/list" element={<ProtectedRoute><RQViewList/></ProtectedRoute>} />
        <Route path="/requisicion/viewer/:rqId" element={<ProtectedRoute><RQViewer/></ProtectedRoute>} />
        <Route path="/bodega/:parentId" element={<ProtectedRoute><Subfolder /></ProtectedRoute>} />
        <Route path="/bodega/info/:folderId" element={<ProtectedRoute><BodegaInfo /></ProtectedRoute>} />
        <Route path="/usuario" element={<ProtectedRoute><GestionUsuarios /></ProtectedRoute>} />
        <Route path="/create/user" element={<CreateUser />} />
        <Route path="/oc/editor/rq/:rqId" element={<ProtectedRoute><OCEditor /></ProtectedRoute>} />
        <Route path="/oc/viewer/:ocId" element={<ProtectedRoute><OCViewer /></ProtectedRoute>} />
        <Route path="/create/element" element={<ProtectedRoute><CreateElement /></ProtectedRoute>} />
        <Route path="/elementos" element={<ProtectedRoute><Elementos /></ProtectedRoute>} />
        <Route path="/elemento/:id" element={<ProtectedRoute><ElementInformation /></ProtectedRoute>} />
        <Route path="/usuario/:userId" element={<ProtectedRoute><UserPanel /></ProtectedRoute>} />
      </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
