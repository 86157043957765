import { useQuery } from "@apollo/client";
import { GET_WORKERS } from "../api/myQueries";
import WORKER_MOCK from "../data/mock.workers.json";
import { useEffect, useState } from "react";

type SelectBox = {
    onChange: (
      evt: React.ChangeEvent<HTMLSelectElement>
    ) => void;
    label: string;
    name: string;
    defaultOption: {label: string, value: string}
    isEmpty: boolean
    value: string
  };
  export default function WorkerSelectBox({ onChange, label, name, defaultOption, isEmpty, value }: SelectBox) {
    return (
      <div className={`input_container gap_12 ${isEmpty ? 'error' : ''}`}>
        <label htmlFor={name}>{label}</label>
        <select className="editable_input width_100" id={name} name={name} onChange={onChange} value={value}>
            <option value={defaultOption.value}>{defaultOption.label}</option>
            <WorkerOptions/>
        </select>
    </div>
    )
  }
  
  function WorkerOptions(){
    const {loading: workerLoading, error:workerError, data: worker} = useQuery(GET_WORKERS);
    
    if(worker){
      return (worker.getWorkers.map((option:PricaWorker)=><option key={option._id} value={option._id}>{option.name}</option>));
    }

    if(workerLoading){
      return (
        <option value="">Cargando Información</option>
    );
    }

    if(workerError){
      return (
        <option value="">Hubo un error</option>
    );
    }

    return <option value="">Estamos procesando la información</option>

  }